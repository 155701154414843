@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Stapel";
  src: url("../src/assets/fonts/Stapel-Regular.woff") format("woff2"),
    url("../src/assets/fonts/Stapel-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* For Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888;
}

/* For Internet Explorer and Edge */
body {
  scrollbar-width: thin;
}
